import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { publicRequest } from "../requestMethod";
import ResetPassword from "../Pages/Auth/Forget/ResetPassword/ResetPassword";

const initialState = {
  token: null,
  isLoggedIn: false,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      document.cookie = `token=${action.payload.token}; path=/; SameSite=Strict; Secure`;
      localStorage.setItem("userId", JSON.stringify(action.payload?.user?._id));
      localStorage.setItem(
        "userName",
        JSON.stringify(
          action.payload?.user?.fullName + " " + action.payload?.user?.lastName
        )
      );
      localStorage.setItem("userEmail", action.payload?.user?.email);
      localStorage.setItem("userData", JSON.stringify(action.payload?.user));
    },
    loginFailure: (state, action) => {
      state.token = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = action.payload;
      //   alert(action.payload);
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = null;
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout } =
  authSlice.actions;

export default authSlice.reducer;

export const loginUser =
  ({ email, password, setForgot, setCurrent, setEmail }) =>
  async (dispatch) => {
    const credentials = {
      email,
      password,
    };
    console.log("credentials", credentials);
    try {
      dispatch(loginRequest());

      const res = await publicRequest.post(
        "/partner/auth/login",
        JSON.stringify(credentials),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res.data", res.data);
      if (res.data.user.isResetPassword) {
        console.log(res.data.user.isResetPassword);
        // <ResetPassword setCurrent={setCurrent} email={email} />;
        setForgot(true);
        setCurrent("Reset");
        setEmail(email);
      } else {
        dispatch(loginSuccess(res.data));
        message.success("Login Successfully");
        return res.data;
      }
    } catch (err) {
      console.error("Login error:", err);

      if (err.response) {
        dispatch(loginFailure(err.response.data.error));
        message.error(err.response.data.error);
      } else if (err.request) {
        dispatch(
          loginFailure("No response from server. Please try again later.")
        );
        message.error("No response from server. Please try again later.");
      } else {
        dispatch(loginFailure(err.message));
        message.error(err.message);
      }

      return err.response ? err.response.data.error : err.message;
    }
  };
